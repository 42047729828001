<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>广告图</el-breadcrumb-item>
                <el-breadcrumb-item>列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div class="search">
                <el-input placeholder="搜索页面名中存在字符" v-model="searchVal"></el-input>
            </div>
            <div></div>
        </div>
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="id" align="center" label="id"> </el-table-column>
                <el-table-column prop="title" align="center" label="页面名"> </el-table-column>
                <el-table-column prop="update_time" align="center" label="更新时间"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog title="修改" :visible.sync="dialogVisible" width="80%" :before-close="bfc">
            <div v-if="dialogVisible">
                <imgList :list="list" :type="type" ref="imgList"></imgList>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="qd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import imgList from "./imgs";
import qs from "qs";

export default {
    components: { imgList },
    data() {
        return {
            tab: [],
            searchVal: "",
            dialogVisible: false,
            // 打开编辑弹框的数据
            list: [],
            // 打开编辑弹框的类型
            type: null,
        };
    },
    async created() {
        this.tab = await this.loading();
    },
    methods: {
        async loading() {
            let { data } = await axios.get("/api/advert/show");
            return data;
        },
        // 打开编辑弹框
        async openSet({ id, type }) {
            let { data } = await axios.get("/api/advert/details", { params: { id } });
            this.type = type;
            this.list = data;
            this.dialogVisible = true;
        },
        async qd() {
            let { data } = await axios.put("/api/advert/update", qs.stringify(this.$refs["imgList"].getData()));
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.tab = await this.loading();
            }

            this.dialogVisible = false;
        },
    },
    computed: {
        tableData() {
            if (!this.searchVal) return this.tab;
            return this.tab.filter(e => e.title.indexOf(this.searchVal) !== -1);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
        }
    }
}
</style>
